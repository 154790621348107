import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import CustomButton from './CustomButton';
import { uploadFile } from "services/uploadService";
import { useSnackbar } from "contexts";

const ChooseFileButton = ({ 
  name='btn-upload', 
  onFileSelected, 
  accept = [], 
  disabled, 
  label , 
  buttonSize , 
  variant 
}) => {
  return (
    <label htmlFor={name}>
      <input
        id={name}
        name={name}
        style={{ display: 'none' }}
        type="file"
        onChange={onFileSelected}
        accept={accept.join(",")}
        disabled={disabled}
      />
      <CustomButton
        variant={variant}
        component="span"
        disabled={disabled}
        size={buttonSize}
      >
        {label}
      </CustomButton>
    </label>
  );
};

export default function FileUpload({
  name,
  uploadLocation,
  file = { name: undefined, url: "" },
  toPublic = false,
  accept = [],
  onUpload = () => { },
  onUploadCancelled = () => { },
  errors = {},
  setErrors = () => { },
  readOnly = false,
  label = 'Choose file',
  setFileName,
  setFileNameReceived,
  uploadOnAws,
  fileNames=[],
  onFileSelect = () => {
    return true;
  },
  buttonSize='small',
  variant='contained',
  ...props
}) {
  const snackbar = useSnackbar();

  const [selectedFile, setSelectedFile] = React.useState(file);
  const [fileDataUpload, setFileDataUpload] = React.useState();
  const [scrapedDataReceived , setScrapedDataReceived] = React.useState(false)

  const generateUniqueFileName = (baseName, existingFileNames) => {
    if (existingFileNames.length === 0) {
      return baseName;
    }

    let uniqueName = baseName;
    let counter = 1;
    const existingNamesSet = new Set(existingFileNames);

    while (existingNamesSet.has(uniqueName)) {
      uniqueName = `${baseName}-${String(counter).padStart(2, '0')}`;
      counter++;
    }

    return uniqueName;
  };

  const selectFile = (event) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024 && uploadOnAws) {
      snackbar.error('File size exceeds the limit of 2MB.');
      return;
    }

    setSelectedFile(file);
    setScrapedDataReceived(onFileSelect(file))

    let fileNameWithoutExtension = file.name.replace(/\.pdf$/i, '');

    fileNameWithoutExtension = generateUniqueFileName(fileNameWithoutExtension, fileNames);

    setFileName(fileNameWithoutExtension)
    setFileNameReceived(true)
    
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      setFileDataUpload(fileContent); 
    };
    reader.readAsDataURL(file);
  };

  const handleUploadSuccess = (uploadedFile) => {
    if (onUpload && typeof onUpload === 'function') onUpload(uploadedFile);
  };

  useEffect(() => {
    const uploadFileIfNeeded = async () => {
      try {
        if (uploadOnAws && fileDataUpload && selectedFile && !selectedFile.url) {

          if (scrapedDataReceived) {
            try {
              const url = await uploadFile(selectedFile, fileDataUpload, uploadLocation, toPublic);
              const uploadedFile = {
                name: selectedFile.name,
                url,
              };
              handleUploadSuccess(uploadedFile);
            } catch (uploadError) {
              setErrors(true);
              throw new Error(`Upload failed: ${uploadError.message}`);
            }
          } else {
            setErrors(true);
          }
        }
      } catch (e) {
        console.error(e);
        setErrors(true)
      }
    };

    if (uploadOnAws) {
      uploadFileIfNeeded();
    }
  }, [fileDataUpload, selectedFile]);

  useEffect(() => {
    if (file.name) {
      setSelectedFile(file);
    } else {
      setSelectedFile(file);
    }
  }, [file]);

  const hasUploadLocation = useMemo(() => {
    return !!uploadLocation;
  }, [uploadLocation]);

  return (
    <Box { ...props}>
        <ChooseFileButton
          onFileSelected={selectFile}
          accept={accept}
          label={label}
          name={name}
          buttonSize={buttonSize}
          variant={variant}
        />
    </Box>
  );
}
