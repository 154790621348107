export const DocumentOptions = {
  JD_RESUME :{
    _id : 'JD_&_RESUME',
    name : 'Practice with available Job descriptions & your Resume',
    label: '',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/jd+and+resume.png'
  },
  JD : {
    _id : 'JD',
    name : 'Practice with available Job descriptions',
    label: '(if you don’t have resume)',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/jd.png'
  },
  RESUME : {
    _id : 'RESUME',
    name : 'Practice for Job role with your resume',
    label: '(Not company specific)',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/resumeU.png'
  },
  CASE_STUDY : {
    _id : 'CASE_STUDY',
    name : 'Report based interview',
    label : '(Practice based on Case study, Product teardown, Project reports & more)',
    img : 'https://languify-assets.s3.ap-south-1.amazonaws.com/images/reportU.png'
  }
}

export const InterviewSteps = {
    JOB_DETAILS : {
      _id: 'JOB_DETAILS',
      name : 'Job Details'
    },
    SELECT_RESUME : {
      _id : 'SELECT_RESUME',
      name : 'Select Resume'
    },
    PREVIEW : {
      _id : 'PREVIEW',
      name : 'Preview'
    },
}