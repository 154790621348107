import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Dialog, DialogContent, Typography, Slide } from '@mui/material';

import StartAssessmentDialog from './StartAssessmentDialog';
import useStore from '../components/CreateInterview/store';
import { PersonalizedAssesmentSteps } from 'components/Profile/constants';
import { useSnackbar } from "contexts";
import { useNavigateWithClient } from 'hooks';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    padding: 0,
    height: '457px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  landingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(9),
    width: '512px',
    height: '100%'
  },
  circularGif: {
    opacity: '50%'
  },
  assessmentDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '457px',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  center: {
    textAlign: 'center',
    height:'72px'
  },
  circularGifLeft: {
    position: 'absolute',
    right: '84%',
    opacity: '10%'
  },
  circularGifRight: {
    position: 'absolute',
    left: '84%',
    opacity: '10%'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '512px',
    gap: theme.spacing(3)
  },
  resumeImgContainer: {
    position: 'relative',
    width: '168px',
    height: '208px'
  },
  resumeImg: {
    width: '100%',
    height: '100%',
    display: 'block',
    animation: '$moveUp 0.2s ease-out forwards',
  },
  animatedLine: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '3px',
    backgroundColor: theme.palette.danger['clr-300'],
    animation: '$lineAnimation 2s ease-in-out infinite'
  },
  '@keyframes lineAnimation': {
    '0%, 100%': {
      bottom: 33,
    },
    '50%': {
      bottom: '112%',
    }
  },
  '@keyframes moveUp': {
    '0%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(-30px)', 
    }
  },
  countdownBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(10),
    marginBottom : theme.spacing(25)
  },
  countText:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    position:'relative'
  },
  countDownGif:{
    position:'absolute',
    width:'400px'
  }
}));


const StartPersonalizedAssessmentDialog = ({ 
  openDialog, onClose , responseReceived=false , template , error=false , noResume=false
}) => {
  const classes = useStyles();
  const navigateWithClient = useNavigateWithClient();
  const snackbar = useSnackbar();

  const audioRef = useRef(null);
  const JDResumeAudioRef = useRef(null);
  const interviewSetAudioRef = useRef(null);
  const countdownZeroAudioRef = useRef(null); 

  const selectedDocument =  useStore(store => store.selectedDocument);

  const [stepIndex, setStepIndex] = useState(0);
  const [countdownStart , setCountdownStart] = useState(false)
  const [countdownValue, setCountdownValue] = useState(3);
  const [loading , setLoading] = useState(false)

  const stepsArray = Object.keys(PersonalizedAssesmentSteps);
  let soundTimeout;
 

  const handleClose = () => {
    if(stepsArray[stepIndex] === PersonalizedAssesmentSteps.LETS_GO._id){
      onClose(false)
      setStepIndex(0);
      navigateWithClient(`/personalized-interview`);
    }
    if (audioRef.current) {
      audioRef.current.pause(); 
    }
    if (JDResumeAudioRef.current) {
      JDResumeAudioRef.current.pause();
    }
    if (interviewSetAudioRef.current) {
      interviewSetAudioRef.current.pause();
    }
  };

  useEffect(()=>{
    onClose(false)
    setStepIndex(0);
  },[error])
      
  useEffect(() => {
    if(openDialog){
      let timer;
  
      const skipStep = () => {
        const currentStep = stepsArray[stepIndex];

        if (
          (currentStep === PersonalizedAssesmentSteps.EXTRACTING_RESUME._id || 
           currentStep === PersonalizedAssesmentSteps.ANALYZING_JD._id) 
          && selectedDocument === 'CASE_STUDY'
        ) {
          setStepIndex(prevIndex => prevIndex + 1);
        } 
        else if (
          currentStep === PersonalizedAssesmentSteps.ANALYZING_REPORT._id && 
          (selectedDocument === 'JD' || selectedDocument === 'JD_&_RESUME' || selectedDocument === 'RESUME')
        ) {
          setStepIndex(prevIndex => prevIndex + 1);
        } else  if (currentStep === PersonalizedAssesmentSteps.EXTRACTING_RESUME._id && (selectedDocument === 'JD' || noResume)) {
          setStepIndex(prevIndex => prevIndex + 1);
        }
        else if (currentStep === PersonalizedAssesmentSteps.ANALYZING_JD._id && selectedDocument === 'RESUME') {
          setStepIndex(prevIndex => prevIndex + 1);
        }
      };
      
      
  
    const loopAIAndGeneratingSteps = () => {
      const currentStep = stepsArray[stepIndex];
      if (currentStep === PersonalizedAssesmentSteps.AI_MAGIC._id) {
        setStepIndex(prevIndex => {
          const nextIndex = stepsArray.indexOf(PersonalizedAssesmentSteps.GENERATING_QUESTIONS._id);
          return nextIndex !== -1 ? nextIndex : prevIndex;
        });
      } else if (currentStep === PersonalizedAssesmentSteps.GENERATING_QUESTIONS._id) {
        setStepIndex(prevIndex => {
          const nextIndex = stepsArray.indexOf(PersonalizedAssesmentSteps.AI_MAGIC._id);
          return nextIndex !== -1 ? nextIndex : prevIndex;
        });
      }
    };
  
    const moveToSetInterview = () => {
      setStepIndex(prevIndex => {
        const setInterviewIndex = stepsArray.indexOf(PersonalizedAssesmentSteps.INTERVIEW_SET._id);
        return setInterviewIndex !== -1 ? setInterviewIndex : prevIndex;
      });
    };
  
    if (responseReceived && 
      (stepsArray[stepIndex] === PersonalizedAssesmentSteps.AI_MAGIC._id 
        || stepsArray[stepIndex] === PersonalizedAssesmentSteps.GENERATING_QUESTIONS._id)) {
      moveToSetInterview();
    } else if (stepsArray[stepIndex] !== PersonalizedAssesmentSteps.LETS_GO._id) {
      skipStep(); 
  
      if (stepsArray[stepIndex] === PersonalizedAssesmentSteps.AI_MAGIC._id 
          || stepsArray[stepIndex] === PersonalizedAssesmentSteps.GENERATING_QUESTIONS._id) {
        timer = setInterval(() => {
          loopAIAndGeneratingSteps();
        }, 5000);
      } else {
        timer = setInterval(() => {
          setStepIndex(prevIndex => {
            const nextIndex = prevIndex + 1;
            return nextIndex < stepsArray.length ? nextIndex : prevIndex;
          });
        }, stepsArray[stepIndex] === PersonalizedAssesmentSteps.INTERVIEW_SET._id ? 2000 : 5000);
      }
    }
  
    return () => {
      clearInterval(timer); 
    };
    }
  }, [stepIndex, stepsArray, selectedDocument, responseReceived]);

  useEffect(() => {
    const currentStep = stepsArray[stepIndex];

    if (
      currentStep === PersonalizedAssesmentSteps.ANALYZING_JD._id || 
      currentStep === PersonalizedAssesmentSteps.EXTRACTING_RESUME._id ||
      currentStep === PersonalizedAssesmentSteps.ANALYZING_REPORT._id
    ) {
      clearTimeout(soundTimeout);
      soundTimeout = setTimeout(() => {
        if (JDResumeAudioRef.current) {
          JDResumeAudioRef.current.currentTime = 0; 
          JDResumeAudioRef.current.play().catch(err => {
            console.log('Playback failed:', err);
          });
        }
      }, 4000); 
    } else {
      clearTimeout(soundTimeout); 
    }

    if (
      currentStep === PersonalizedAssesmentSteps.AI_MAGIC._id || 
      currentStep === PersonalizedAssesmentSteps.GENERATING_QUESTIONS._id
    ) {
      if (audioRef.current && audioRef.current.paused) {
        audioRef.current.currentTime = 0; 
        audioRef.current.play().catch(err => {
          console.log('Playback failed:', err);
        });
      }
    }
    if (
      currentStep !== PersonalizedAssesmentSteps.AI_MAGIC._id && 
      currentStep !== PersonalizedAssesmentSteps.GENERATING_QUESTIONS._id
    ) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    }

    if (currentStep === PersonalizedAssesmentSteps.INTERVIEW_SET._id) {
      if (interviewSetAudioRef.current) {
        interviewSetAudioRef.current.currentTime = 0;
        interviewSetAudioRef.current.play().catch(err => {
          console.log('Playback failed:', err);
        });
      }
    }

    return () => {
      clearTimeout(soundTimeout); 
    };
  }, [stepIndex, stepsArray]);

  useEffect(() => {
    if (countdownStart) {
      setStepIndex(stepsArray.indexOf(PersonalizedAssesmentSteps.COUNTDOWN._id));
    }
  }, [countdownStart, stepsArray]);

  useEffect(() => {
    if (stepsArray[stepIndex] === PersonalizedAssesmentSteps.COUNTDOWN._id) {
      const countdownInterval = setInterval(() => {
        setCountdownValue(prevValue => {
          if (prevValue > 0) {
            return prevValue - 1;
          } else {
            clearInterval(countdownInterval);
            proceedToTest();
            return 0;
          }
        });
      }, 1000);
  
      return () => clearInterval(countdownInterval);
    }
  }, [stepIndex, stepsArray]);
  

  const proceedToTest = async () => {
    try {
        setLoading(true);

        const { _id, maxAllowedAttempts, attempts } = template;

        if (
            maxAllowedAttempts &&
            maxAllowedAttempts !== -1 &&
            attempts >= maxAllowedAttempts
        ) {
            throw new Error(
                'You have reached max limit of attempts for this assessment!'
            );
        }

        navigateWithClient(`/assessment?tid=${_id}`);
        setStepIndex(0)
    } catch (error) {
        console.error(error);
        snackbar.error(error.message);
    } finally {
        setLoading(false);
    }
}

  useEffect(() => {
    if (countdownValue === 0) {
      if (countdownZeroAudioRef.current) {
        countdownZeroAudioRef.current.currentTime = 0;
        countdownZeroAudioRef.current.play()        
    }}
  }, [countdownValue]);
  

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth={true}
      open={openDialog}
    >
      <DialogContent className={classes.dialogContainer}>
        {stepsArray[stepIndex] === PersonalizedAssesmentSteps.LANDING_SCREEN._id
          ? 
            (
              <Box className={classes.landingContainer}>
                <img
                  src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/circlularGifPersonalized.gif'
                  alt='gif'
                  width={200}
                  height={200}
                  className={classes.circularGif}
                />
                <Typography className={classes.center} variant='h4-medium' color='neutral.clr-700'>
                  It takes less than a minute to generate personalized mock interview for you...
                </Typography>
              </Box>
            )
          : (
            <Box className={classes.assessmentDialog}>
              <img
                src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/circlularGifPersonalized.gif'
                alt='gif'
                width={298}
                height={298}
                className={classes.circularGifLeft}
              />
              <img
                src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/circlularGifPersonalized.gif'
                alt='gif'
                width={298}
                height={298}
                className={classes.circularGifRight}
              />
              {stepsArray[stepIndex] === PersonalizedAssesmentSteps.ANALYZING_JD._id && (
                <Box className={classes.contentBox}>
                  <Box className={classes.resumeImgContainer}>
                    <img
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/jdAssessment.png'
                      alt='gif'
                      className={classes.resumeImg}
                    />
                    <div className={classes.animatedLine}></div>
                  </Box>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography className={classes.center} variant='h4-medium' color='neutral.clr-700'>
                      Our AI is analyzing the job description for preparing questions...
                    </Typography>
                  </Slide>
                </Box>
              )}
              {stepsArray[stepIndex] === PersonalizedAssesmentSteps.EXTRACTING_RESUME._id && (
                <Box className={classes.contentBox}>
                  <Box className={classes.resumeImgContainer}>
                    <img
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/rseumeAssessment.png'
                      alt='gif'
                      className={classes.resumeImg}
                    />
                    <div className={classes.animatedLine}></div>
                  </Box>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography className={classes.center} variant='h4-medium' color='neutral.clr-700'>
                      Collecting your information from the resume sections to prepare questions...
                    </Typography>
                  </Slide>
                </Box>
              )}
              {stepsArray[stepIndex] === PersonalizedAssesmentSteps.ANALYZING_REPORT._id && (
                <Box className={classes.contentBox}>
                  <Box className={classes.resumeImgContainer}>
                    <img
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/report-scanning-img.png'
                      alt='gif'
                      className={classes.resumeImg}
                    />
                    <div className={classes.animatedLine}></div>
                  </Box>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography className={classes.center} variant='h4-medium' color='neutral.clr-700'>
                      Our AI is analyzing content for generating questions...
                    </Typography>
                  </Slide>
                </Box>
              )}
             {stepsArray[stepIndex] === PersonalizedAssesmentSteps.AI_MAGIC._id && (
                <Box className={classes.contentBox}>
                  <img
                    src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/aiAssessment.gif'
                    alt='gif'
                    width={200}
                    height={200}
                  />
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography className={classes.center} variant='h4-medium' color='neutral.clr-700'>
                      While AI is doing its magic, you can enjoy some music and relax...
                    </Typography>
                  </Slide>
                </Box>
              )}
              {stepsArray[stepIndex] === PersonalizedAssesmentSteps.GENERATING_QUESTIONS._id && (
                <Box className={classes.contentBox}>
                  <img
                    src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/aiAssessment.gif'
                    alt='gif'
                    width={200}
                    height={200}
                  />
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography className={classes.center} variant='h4-medium' color='neutral.clr-700'>
                      Your questions are almost ready!
                    </Typography>
                  </Slide>
                </Box>
              )}
              {stepsArray[stepIndex] === PersonalizedAssesmentSteps.INTERVIEW_SET._id && (
                <Box className={classes.contentBox}>
                  <img
                    src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/double-check.gif'
                    alt='gif'
                    width={200}
                    height={200}
                  />
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography className={classes.center} variant='h4-medium' color='neutral.clr-700'>
                      Your interview is set
                    </Typography>
                  </Slide>
                </Box>
              )}
              {stepsArray[stepIndex] === PersonalizedAssesmentSteps.LETS_GO._id && (
                <Box className={classes.contentBox}>
                  <StartAssessmentDialog 
                    isPersonalizedInterview={true}
                    setCountdownStart={setCountdownStart}
                  />
                </Box>
              )}
              {stepsArray[stepIndex] === PersonalizedAssesmentSteps.COUNTDOWN._id && (
                <Box className={classes.contentBox}>
                  <Box className={classes.countdownBox}>
                    <Typography  variant='h4-medium' color='neutral.clr-700'>
                    {countdownValue > 0 ? "It’s showtime!!" : "Let's go!"}
                    </Typography>
                    <Box className={classes.countText}>
                      <Typography 
                        className={classes.center} 
                        variant='h1-xl-regular' 
                        color='primary.clr-300'
                      >
                          {countdownValue}
                      </Typography>
                      {countdownValue === 0 && (
                        <img
                          src={`https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/let's+go+gif.gif`}
                          alt='gif'
                          className={classes.countDownGif}
                      />
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
      </DialogContent>
      <audio ref={audioRef}>
        <source 
          src={'https://languify-assets.s3.ap-south-1.amazonaws.com/audio/waiting+music.mp3'} 
          type="audio/mp3" 
        />
      </audio>
      <audio ref={JDResumeAudioRef}>
        <source 
          src={'https://languify-assets.s3.ap-south-1.amazonaws.com/audio/JD%26Resume.wav'} 
          type="audio/wav" 
        />
      </audio>
      <audio ref={interviewSetAudioRef}>
        <source 
          src={'https://languify-assets.s3.ap-south-1.amazonaws.com/audio/interview+set.wav'} 
          type="audio/wav" 
        />
      </audio>
      <audio ref={countdownZeroAudioRef}>
        <source 
          src={`https://languify-assets.s3.ap-south-1.amazonaws.com/audio/let's+go+count+zero.mp3`} 
          type="audio/wav" 
        />
      </audio>
    </Dialog>
  )
};

export default StartPersonalizedAssessmentDialog;
