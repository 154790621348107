import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useTheme } from '@emotion/react';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from 'dayjs';

import { useSnackbar } from 'notistack';
import { useServices } from 'contexts';
import { useNavigateWithClient } from 'hooks';
import { getAverageScore, round } from 'utils';
import { getPersonalizedInterview } from 'services';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SearchTextField } from 'components/CustomFields';
import { CloseLock, OpenLock } from 'components/Icons';
import { BootstrapTooltip } from 'components/InfoIconWithTooltip';
import DataGrid from 'components/DataGrid';
import NoAssessmentsFound from 'components/NoAssessmentsFound';
import Pagination from 'components/Pagination';
import CustomButton from 'components/CustomButton';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary['clr-50'],
    ...theme.typography['body01-bold'],
    textAlign: "center",
    lineHeight: 'none',
    color: theme.palette.shades['clr-black-900'],
  },
  row: {
    color: theme.palette.neutral['clr-900'],
    ...theme.typography['body01-medium'],
    cursor: "pointer",
    '&:hover': {
      backgroundColor: `${theme.palette.neutral['clr-50']} !important`,
    },
  },
  LockRoot: {
    borderRadius: '50%',
    height: '34px', width: '34px',
    display: 'flex', alignItems: 'center', justifyContent: 'center'
  }
}));

const PersonalizedInterviewTable = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const service = useServices();
  const navigateWithClient = useNavigateWithClient();
  const theme = useTheme();

  const [templates, setTemplates] = useState([]);
  const [assessment, setAssessment] = useState();
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      field: "name",
      headerName: "Assessment Name",
      valueFormatter: (row) => row?.value?.name,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 200,
      align: "left",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        const { totalAttemptsCount, expiresOn, name } = row;
        const attempted = totalAttemptsCount > 0;

        return (
          <Box display='flex' gap={3} alignItems='center'>
            <Box 
              className={classes.LockRoot}
              style={{ backgroundColor: attempted ? theme.palette.success['clr-100'] : '#E1E3E5'}}
            >
              {
                attempted 
                  ? 
                  <BootstrapTooltip 
                    title={<><b>Feedback unlocked!</b><br/> Tap anywhere on this row to check your performance.</>} 
                    placement='top'
                  >
                    <Box>
                      <OpenLock color={theme.palette.success['clr-500']}/>
                    </Box>
                  </BootstrapTooltip>
                  : 
                  <BootstrapTooltip 
                    title={<><b>Feedback locked.</b><br/> Finish your attempt to unlock it!</>} 
                    placement='top'
                  >
                    <Box>
                      <CloseLock
                        color={ dayjs().isAfter(expiresOn)
                          ? theme.palette.neutral['clr-400'] 
                          : theme.palette.neutral['clr-900']
                        }
                      />
                    </Box>
                  </BootstrapTooltip>
              }

            </Box>
            <Typography variant='body01-medium'>
              { name }
            </Typography>
          </Box>
        )
      }
    },
    {
      field: "createdAt",
      headerName: "Created On",
      align: "center",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
    },
    {
      field: "totalAttemptsCount",
      headerName: "Total Attempts",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "center",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { totalAttemptsCount, expiresOn } = row;
        const attempted = totalAttemptsCount > 0;

        return (
          attempted ?
            <CustomButton
              sx={{ width: '100%' }}
              variant='outlined'
              onClick={(e) => {
                e.stopPropagation();
                setAssessment(row)
              }}
              disabled={ dayjs().isAfter(expiresOn) }
            >
              Retake
            </CustomButton>
          :
            <CustomButton
              sx={{ width: '100%' }}
              onClick={(e) => {
                e.stopPropagation();
                setAssessment(row)
              }}
              disabled={ dayjs().isAfter(expiresOn) }
            >
              Attempt now
            </CustomButton>
        )
      },
    },
    {
      field: "overallScore",
      headerName: "Overall score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) => {
        const comm = round(row.communicationAverage) || 0;
        const content = round(row.contentAverage) || 0;

        return round(getAverageScore(comm, content));
      },
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "communication",
      headerName: "Speech score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) =>
        round(row.communicationAverage) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "content",
      headerName: "Content score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) =>
        round(row.contentAverage) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
  
        let allFilters = {
          type: "personalized",
        };
  
        const { interviewTemplates } = await service.callService(
          getPersonalizedInterview,
          allFilters
        );
        
        const sortedTemplates = interviewTemplates.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
  
        setTemplates(sortedTemplates);
        setFilteredTemplates(sortedTemplates);
      } catch (error) {
        console.log(error);
        snackbar.error('Unable to load attempts');
      } finally {
        setLoading(false);
      }
    })();
  }, []);  

  useEffect(() => {
    if (searchQuery.length > 0) {
      const filtered = templates.filter((template) =>
        template.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates(templates);
    }
  }, [searchQuery, templates]);

  const handleChange = (e) => {
    const value = e.target.value.trim();
    setSearchQuery(value);
  };

  const handleInput = debounce(handleChange, 500);

  return (
    <WhiteBox sx={{ marginTop: '16px' }}>
      <WhiteBoxHeader
        heading="My performance"
        subheading="Overview of your performance in personalized interviews"
      >
        <SearchTextField
          placeholder="Search here"
          onChange={handleInput}
          disabled={!templates?.length && !searchQuery?.length > 0}
        />
      </WhiteBoxHeader>

      {filteredTemplates?.length ? (
        <DataGrid
          hideFooter
          loading={loading}
          paginationMode={'client'}
          filterMode={'client'}
          sortingMode={'client'}
          height="318px"
          rows={filteredTemplates}
          columns={columns}
          rowCount={filteredTemplates.length}
          pageSize={5}
          page={page}
          onPageChange={setPage}
          components={{ Pagination: null }}
          getRowClassName={(params) => classes.row}
          density="standard"
          noRowsMessage={"Seems like, you haven't taken any assessment yet!"}
          onRowClick={(params) => {
            if(params.row.totalAttemptsCount !== 0){
              navigateWithClient(`/performance/assessment/${params.id}`)
            }}}
        />
      ) : (
        <NoAssessmentsFound
          icon={'https://languify-assets.s3.ap-south-1.amazonaws.com/images/NoPersonalizedAssess.svg'}
          label={
                  filteredTemplates.length === 0 && searchQuery 
                    ? <>No matches found.<br/> Did you spell everything correctly?</> 
                    : <>You have not created <br />any personalized interview so far!</>
                }
        />
      )}
      {!filteredTemplates?.length ? null :
        <Box p={2} display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(filteredTemplates.length/5)}
            page={page}
            setPage={setPage}
          />
        </Box>
      }

      <StartAssessmentDialog
        onClose={() => setAssessment(undefined)}
        open={!!assessment}
        template={assessment}
        userMock={true}
      />
    </WhiteBox>
  );
}

export default PersonalizedInterviewTable;
